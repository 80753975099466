import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEOPage"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import {
  wrapper,
  color,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import GetWindowWidth from "../../components/shared/GetWindowWidth"

const WrapperDesktop = styled("div")`
  ${wrapper}
  .second-img {
    width: 100%;
    max-width: 50%;
    float: left;
    margin: 0 24px 24px 0;
    .gatsby-image-wrapper {
      border-radius: 24px;
    }
  }
  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
  .features {
    ul,
    ol {
      padding-left: 20px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .second-img {
      max-width: 100%;
      float: none;
    }
  }
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper};
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .top-wrap {
    position: absolute;
    top: 25%;
    left: 32px;
    right: 32px;
  }
  h1 {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
    max-height: 448px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: ${color.transparentBlack};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 24px;
    }
  }
  img {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 16px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      top: 30%;
      left: 16px;
      right: 16px;
    }
    h1 {
      font-size: 22px;
      line-height: 30px;
      margin: 0;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;
`

const BottomBanner = styled("div")`
  background: linear-gradient(
    128.46deg,
    rgba(169, 0, 0, 0.4) 25.23%,
    rgba(120, 0, 0, 0.1) 88.32%
  );
  padding: 32px 0;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: ${color.gold};
  }
`

const BottomColumn = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  h3 {
    margin-top: 0;
  }

  .box {
    padding: 16px;
    margin-bottom: 24px;
    background: ${color.transparentWhite1};
    border-radius: 24px;
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: none;
    grid-gap: 0;
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const currentPage = data.wordpressPage
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const page_subtitle = currentPage.acf.top_subtitle
  const featuredMedia = currentPage.featured_media
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const content_field_one = currentPage.acf.content_field_01
  const content_field_two = currentPage.acf.content_field_02
  const content_field_tree = currentPage.acf.content_field_03
  const content_field_four = currentPage.acf.content_field_04
  const page_features = currentPage.acf.page_features
  const page_casino_games = currentPage.acf.page_casino_games
  const page_casino_rewards = currentPage.acf.page_casino_rewards
  // const page_vip_gaming = currentPage.acf.page_vip_gaming
  const page_selection = currentPage.acf.page_selection
  const cta_text = currentPage.acf.cta_text
  const cta_content = currentPage.acf.cta_content
  const cta_footer = currentPage.acf.cta_footer
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid &&
        isDesktop ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : page_title
              }
              fadeIn={false}
              loading="eager"
            />
            <div className="top-wrap">
              <h1
                className="h1 rich-design"
                dangerouslySetInnerHTML={{ __html: page_title }}
              />
              {page_subtitle && (
                <span
                  className="subtitle"
                  dangerouslySetInnerHTML={{ __html: page_subtitle }}
                />
              )}
            </div>
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
            {page_subtitle && (
              <span dangerouslySetInnerHTML={{ __html: page_subtitle }} />
            )}
          </NoFeaturedWrapper>
        )}

        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <div>
            <div className="second-img">
              <Img
                fluid={featuredMedia.localFile.childImageSharp.fluid}
                alt={
                  currentPage.featured_media.alt_text
                    ? currentPage.featured_media.alt_text
                    : page_title
                }
                fadeIn={false}
                loading="eager"
              />
            </div>
            {page_features && (
              <div>
                <h2 className="subtitle">Features</h2>
                <span
                  className="features"
                  dangerouslySetInnerHTML={{ __html: page_features }}
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            {page_features && (
              <span
                className="features"
                dangerouslySetInnerHTML={{ __html: page_features }}
              />
            )}
          </div>
        )}

        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          <BottomColumn>
            <div className="col">
              {content_field_one && (
                <div className="box">
                  <span
                    dangerouslySetInnerHTML={{ __html: content_field_one }}
                  />
                </div>
              )}
              {content_field_tree && (
                <div className="box">
                  <span
                    dangerouslySetInnerHTML={{ __html: content_field_tree }}
                  />
                </div>
              )}
              {page_casino_games && (
                <div className="box">
                  <h3>Casino Games</h3>
                  <span
                    dangerouslySetInnerHTML={{ __html: page_casino_games }}
                  />
                </div>
              )}
              {cta_content && (
                <div className="box">
                  <span dangerouslySetInnerHTML={{ __html: cta_content }} />
                </div>
              )}
            </div>

            <div className="col">
              {content_field_two && (
                <div className="box">
                  <span
                    dangerouslySetInnerHTML={{ __html: content_field_two }}
                  />
                </div>
              )}
              {content_field_four && (
                <div className="box">
                  <span
                    dangerouslySetInnerHTML={{ __html: content_field_four }}
                  />
                </div>
              )}
              {page_casino_rewards && (
                <div className="box">
                  <h3>Casino Rewards</h3>
                  <span
                    dangerouslySetInnerHTML={{ __html: page_casino_rewards }}
                  />
                </div>
              )}
              {page_selection && (
                <div className="box">
                  <h3>Selection</h3>
                  <span dangerouslySetInnerHTML={{ __html: page_selection }} />
                </div>
              )}
              {cta_text && (
                <div className="box">
                  <span dangerouslySetInnerHTML={{ __html: cta_text }} />
                </div>
              )}
            </div>
          </BottomColumn>
        </ContentWrapper>
      </WrapperDesktop>
      {cta_footer && (
        <BottomBanner>
          <WrapperDesktop>
            <span dangerouslySetInnerHTML={{ __html: cta_footer }} />
          </WrapperDesktop>
        </BottomBanner>
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_subtitle
        page_features
        page_casino_games
        page_casino_rewards
        page_vip_gaming
        page_selection
        cta_text
        cta_content
        cta_footer
        content_field_01
        content_field_02
        content_field_03
        content_field_04
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
